<script lang="ts">
import AnimatedHero from '$components/Homepage/AnimatedHero.svelte'
import EmbedCarousel from '$components/Homepage/EmbedCarousel.svelte'
import DataVisualization from '$components/Homepage/DataVisualization.svelte'
import CaseStudyCarousel from '$components/Homepage/CaseStudyCarousel.svelte'
import ContactUs from '$components/Global/ContactUs.svelte'
import Banner from '$components/Homepage/Banner.svelte'
import type { PageData } from './$types'

export let data: PageData

const {
  animatedHero,
  productEmbeds,
  dataVisualization,
  caseStudyCarousel,
  contactUs,
} = data
</script>

<AnimatedHero animatedHeroData={animatedHero} />

<EmbedCarousel productEmbedData={productEmbeds} />

<DataVisualization dataVisualizationData={dataVisualization} />

<CaseStudyCarousel caseStudiesData={caseStudyCarousel} />

<ContactUs data={contactUs} />

<slot />
