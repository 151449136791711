<script lang="ts">
import SectionTitle from '$components/Typography/SectionTitle.svelte'
import HomepageSectionSubtitle from '$components/Typography/HomepageSectionSubtitle.svelte'
import Carousel from '$components/Carousel.svelte'

import type { DATA_VISUALIZATION_CONTAINER_TYPE } from '$types/index'

export let dataVisualizationData: DATA_VISUALIZATION_CONTAINER_TYPE

const { sectionTitle, sectionSubtitle, visualizationItems } =
  dataVisualizationData.fields
</script>

<Carousel items={visualizationItems}>
  <svelte:fragment slot="header">
    <SectionTitle text={sectionTitle} />
    <HomepageSectionSubtitle text={sectionSubtitle} />
  </svelte:fragment>
</Carousel>
